import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {
  Table
} from "react-bootstrap";

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import parse from 'html-react-parser';
class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      storelist:'',
      activePage:1,
    };
   
      this.handleInputChange = this.handleInputChange.bind(this);
      this.listloadedData = this.listloadedData.bind(this);
      
    }

    
   handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    console.log(value,'search')
    var postobject = {             
      activePage   : this.state.activePage,
      search_all : value,
      admin_id: localStorage.getItem('admin_id')           
    };
    var qs = require('qs');
    axios.post(apiUrl+"store/list",qs.stringify(postobject)).then(res => {
   
      if(res.data.status === 'success'){
          this.setState({  storelist: res.data, totalRecord: res.data.records_count}, function() {
            this.storelist();
          });
      }else{
       this.setState({  storelist: '', totalRecord: 0}, function() {
         this.storelist();
       });
      }
    }); 
  }
  handleFormSubmit = () => {
      
    this.setState({Loading:true});
    var qs = require('qs');
    const formPayload = this.state;
    
    var postobject = {             
      activePage   : this.state.activePage,
      search_all : this.state.search_all,
      admin_id: localStorage.getItem('admin_id')           
    };
    axios.post(apiUrl+"store/list",qs.stringify(postobject)).then(res => {
   
      if(res.data.status === 'success'){
          this.setState({  storelist: res.data, totalRecord: res.data.records_count}, function() {
            this.storelist();
          });
      }else{
       this.setState({  storelist: '', totalRecord: 0}, function() {
         this.storelist();
       });
      }
    }); 
    // this.listloadedData(postobject)
  
} 
listloadedData(postobject=''){
  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };  
   
 //  this.props.getCategoryList(qs.stringify(postobject))
 axios.post(apiUrl+"store/list",qs.stringify(postobject)).then(res => {
   
   if(res.data.status === 'success'){
       this.setState({  storelist: res.data, totalRecord: res.data.records_count}, function() {
         this.storelist();
       });
   }else{
    this.setState({  storelist: '', totalRecord: 0}, function() {
      this.storelist();
    });
   }
 }); 
}

   componentDidMount() {
    $('.model-popup').hide();
    this.listloadedData()
   }


   componentWillReceiveProps(Props){
    
   }


  handlePageChange(pageNumber) {
     this.setState({activePage: pageNumber});
     var qs = require('qs');
     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getCategoryList(qs.stringify(postobject))
    axios.post(apiUrl+"store/list",qs.stringify(postobject)).then(res => {
   
      if(res.data.status === 'success'){
          this.setState({  storelist: res.data, totalRecord: res.data.records_count}, function() {
            this.storelist();
          });
      }else{
       this.setState({  storelist: '', totalRecord: 0}, function() {
         this.storelist();
       });
      }
    }); 
  }

  copystore(id){
    this.setState({companyid : id})

    $('.confirm-action-message').html('Are you sure! you want to clone?');	
    $('.confirm-action').addClass('show');	
    
   }


   deletestore(id){
    this.setState({companyid : id})
    $('.confirm-action-delete-message').html('Are you sure! you want to delete the store?');	
    $('.confirm-action-delete').addClass('show');	
   }


   storelist() {
    var storelist = this.state.storelist;
    if (storelist !== undefined && storelist !== null) {
      if (storelist.status === "success") {
        if (storelist.storelist.length > 0) { // Check the length of storelist.storelist
          const storelistDetails = storelist.storelist.map((store, index) => {
            let sno = index + 1;
            let status = store.store_status === 'Yes' ? 'Active' : 'Inactive';
            let domainlist = store.domains;
            let domvalu = '';
            if (domainlist !== undefined) {
              domvalu = domainlist.map((doms) => {
                return doms.domain_name;
              }).join(',');
            } else {
              domvalu = 'N/A';
            }
            let store_contact = (store.store_contact == null || store.store_contact === '') ? 'N/A' : store.store_contact;
            return (
              <tr key={store.store_id}>
                <td>{sno}</td>
                <td>{store.store_name}</td>
                <td>{store.productCatgName}</td>
                <td>{store_contact}</td>
                <td>{store.store_username}</td>
                <td>{store.store_org_password}</td>
                <td>{store.store_address}</td>
                <td>{status}</td>
                <td className="actiongroup">
                  <Link to={{ pathname: '/store-edit/' + store.store_id }} className="" title="Edit">
                    <i className="ft-edit-3" aria-hidden="true"></i>
                  </Link>
                  <Link onClick={this.deletestore.bind(this, store.store_id)} className="" title="Copy Category">
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </Link>
                </td>
              </tr>
            );
          });
          return storelistDetails;
        } else {
          return (
            <tr>
              <td colSpan="11" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      } else {
        
        return (
          <tr>
            <td colSpan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    } else {
      
      return (
        <tr>
          <td colSpan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  
  cloneConfirm =(confirmstatus)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action').removeClass('show');
		}else{
			$('.confirm-action').removeClass('show');	
		}
  }

  deleteConfirm =(confirmstatus,store_id)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action-delete').removeClass('show');
      var qs = require('qs');       
      var postObject = { 
        column: 'store_id',         
        value: store_id,   
        activePage:this.state.activePage,  
        store_id:store_id
      };
      axios.post(apiUrl+"store/delete",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
          setTimeout(
          function() {
          $('.success_message').html('');
          }
          .bind(this),
          3000
          );

          this.listloadedData()
       
}  
      });  
		}else{
			$('.confirm-action-delete').removeClass('show');	
		}
  }

  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="storelist" />    
  <div className="content"> 
    <div className="content-wrapper">
          <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            <h3>Stores</h3>
          </div> 
          <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
              <a className="add" href='/store-add'>Add</a> 
          </div>
        </div>
       

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Store</th>
                <th>Product Category</th>       
                <th>Contact</th>
                <th>Username</th>
                <th>Password</th>
                <th>Address</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.storelist()}</tbody>
          </Table>
        </div>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
  
                      
      </div>  
    </div>  
  </div>
             <div className="confirm-action">
								<p className="confirm-action-message"></p>
                <Link  to={{ pathname: '/company-clone/'+this.state.companyid}} className="btn" title="Clone Company">Yes </Link>
                <a href="javascript://" onClick={this.cloneConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.cloneConfirm.bind(this, "No")}  >X</span>
						 </div>

             <div className="confirm-action-delete">
								<p className="confirm-action-delete-message"></p>
                
                
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "yes",this.state.companyid)} className="btn">Yes</a>
								<a href="javascript://" onClick={this.deleteConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support" onClick={this.deleteConfirm.bind(this, "No")}  >X</span>
						 </div>
      
    </div>
    );
  }
}
export default List;